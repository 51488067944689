<template lang="html">
  <aside class="menuInstitucional">
    <div class="fundoEscuro" v-show="isOpen" @click="$emit('close-menu')"></div>
    <nav id="menu-responsivo" class="menu-lateral box-esquerdo d-lg-block" :class="{ open: isOpen }">
      <div class="mainMenu-containerMob">
        <button class="closeMenu d-lg-none" @click="$emit('close-menu')">
          <img src="./../assets/img/icon-close.svg" alt="">
        </button>
        <ul class="menu-itens d-flex flex-column align-items-center justify-content-center" style="display: contents;">
          <li v-for="item in menuCalendario" :key="item.nome" @click="$emit('close-menu')">
            <router-link :to="item.rota" exact-active-class="itemAtivo" class="w-100 h-100 d-flex align-items-center">
              <span v-html="item.icon"></span>
              {{ item.nome }}
            </router-link>
          </li>
            <div class="sair-container" style="display: flex; justify-content: center; margin-top: 20px;">
              <button @click="saindo" class="btn-sair"
                style="border: none; cursor: pointer; display: flex; align-items: center; padding: 5px 25px; border-radius: 5px;">
                SAIR <img src="./../assets/img/icon-sair.svg" alt="ícone sair"></button>
            </div>
        </ul>
      </div>
    </nav>
  </aside>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeItem: null, // Armazena o nome do item ativo
      menuCalendario: [
        {
          nome: 'Calendário',
          rota: '/dashboard/home',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>`
        },
        {
          nome: 'Diário de Notas',
          rota: '/dashboard/diarionotas',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 96C0 43 43 0 96 0h96V190.7c0 13.4 15.5 20.9 26 12.5L272 160l54 43.2c10.5 8.4 26 .9 26-12.5V0h32 32c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32H384 96c-53 0-96-43-96-96V96zM64 416c0 17.7 14.3 32 32 32H352V384H96c-17.7 0-32 14.3-32 32z"/></svg>` // Adicione o ícone adequado aqui
        },
        {
          nome: 'Diário de Aula',
          rota: '/dashboard/diarioaula',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z"/></svg>`
        }
      ],
    }
  },
  methods: {
    setActiveItem(itemName) {
      this.activeItem = itemName; // Define o item ativo quando um item é clicado
    },
    deleteCookie(name) {
      document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    saindo() {
      this.deleteCookie('portal do docente');
      this.deleteCookie('Token');
      localStorage.removeItem('user-name');
      localStorage.removeItem('user-uid');
      localStorage.removeItem('user-authenticated')
      this.$router.push('/')
    },
  }
}
</script>

<style lang="css" scoped>
.fundoEscuro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.itemAtivo {
  background-color: #004F9F;
  border-radius: 28px;
  color: #FFFFFF;
}

#menu-responsivo ul.menu-itens li {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: calc(100% - 30px);
}

#menu-responsivo ul.menu-itens li a {
  padding-left: 30px;
}

#menu-responsivo {
  width: 300px;
  border-radius: 10px;
  background-color: #FFFFFF;
  height: 100vh;
  max-height: calc(100vh - 130px);
  margin: 0 30px 0 0;
  float: left;
  overflow: auto;
  flex: 0 0 auto;
}

.itemAtivo {
  /* background-color: blue; */
  color: white;
}

.menu-itens {
  font-family: 'Panton Bold';
  font-size: 18px;
  color: #004F9F;
  font-weight: 700;
  padding-top: 40px;
  padding: 40px 0 0
}

#menu-responsivo ul.menu-itens li svg {
  margin-right: 10px;
  line-height: 27px;
}

.menu-itens li:not(:nth-last-child(1)) {
  margin-bottom: 15px;
}

.menu-itens li img {
  margin-right: 5px;
}

.mainMenu-containerMob {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.navbar-nav {
  width: 100%;
  margin-left: -25px;
}

/* FIM MENU LATERAL */

/* MENU MOBILE */
@media (max-width: 1199px) {
  .menu-itens {
    padding-top: 70px;
  }

  #menu-responsivo {
    width: 300px;
    position: fixed;
    top: 0;
    left: -300px;
    height: 100vh;
    max-height: 100vh;
    z-index: 99999;
    background-color: #FFFFFF;
    transition: 300ms;
    overflow: auto;
    padding-bottom: 40px;
    border-radius: unset;
  }

  .open {
    transform: translate(300px);
  }

  .closeMenu {
    position: absolute;
    top: 10px;
    right: 10px;
    border: unset;
    background: unset;
  }

  .sair-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    /* Adiciona um espaçamento entre os itens do menu e o botão SAIR */
  }

  .btn-sair {
    /* background-color: transparent; */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 25px;
    border-radius: 5px;
    /* transition: background-color 0.3s; */
  }

  /* .btn-sair:hover {
    background-color: #004F9F;
    color: #FFFFFF;
  } */

  .btn-sair img {
    margin-left: 5px;
  }

}
</style>
