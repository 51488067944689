<template>
    <div>
      <h2>Editar Notas</h2>
      <!-- Campos de inserção -->
      <b-form-group label="Matricula:">
        <b-form-input v-model="matricula" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Disciplina:">
        <b-form-input v-model="nome" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Avaliação 1:">
        <b-form-input v-model="av1" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Avaliação 2:">
        <b-form-input v-model="av2" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Avaliação 3:">
        <b-form-input v-model="av3" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Fórum:">
        <b-form-input v-model="forum" type="number"></b-form-input>
      </b-form-group>
      <br>
      <b-button @click="submitItem" variant="primary">Salvar Dados</b-button>
        &nbsp;
      <b-button @click="btnvoltar" variant="warning">Voltar</b-button>
    </div>
  </template>
  
  <script>

import DiarioService from "../../services/DiarioService";

  export default {
    data() {
      return {
        //ano: "",
        turma: "",
        nome: "",
        etapa: "",
        aluno: "",
        matricula: "",
        situacaoMatricula: "",
        av1: 0,
        av2: 0,
        av3: 0,
        forum: 0,
        media: 0,
        //situacao: "",
        originalJSON: {}
      };
    },
  async mounted() {
  if (this.$route.params.itemData) {
    const itemData = JSON.parse(this.$route.params.itemData);
    this.av1 = parseFloat(itemData.Nota1).toFixed(2);
    this.av2 = parseFloat(itemData.Nota2).toFixed(2);
    this.av3 = parseFloat(itemData.Nota3).toFixed(2);
    this.forum = parseFloat(itemData.NotaForum).toFixed(2);
    //this.ano  =  itemData.AnoSemestre;
    this.turma = itemData.CodTurma;
    this.nome = itemData.Disciplina;
    this.etapa = itemData.Etapa;
    this.aluno = itemData.CodAluno
    this.matricula = itemData.Matricula;
    this.media = parseFloat(itemData.NotaMedia).toFixed(2);
    //this.situacao = itemData.NucleoComum;
    this.originalJSON = itemData;

  }
},
  methods: {
    async submitItem() {
      try {


        const response = await DiarioService.AtualizarNotas({

          matricula: this.matricula,
          //disciplina: this.nome,
          nota1: parseFloat(this.av1).toFixed(2),
          nota2: parseFloat(this.av2).toFixed(2),
          nota3: parseFloat(this.av3).toFixed(2),
          forum: parseFloat(this.forum).toFixed(2),
        });
        
        if (response?.status === 200) {
          
          this.$root.$emit("data-edited", this.originalJSON);
         
          this.$router.back();
        } else {
          console.log("Error updating data.");
        }
      } catch (error) {
        console.error("Error editing data:", error);
      }
  },
  btnvoltar(){
    this.$router.push({name:'dashboard-diarionotas'})
  }

  }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  