import Axios from "axios";

/**
 * Configurações gerais das Apis
 */

Axios.defaults.headers.get["Content-Type"] = "application/json";
Axios.defaults.headers.get["Accept"] = "application/json";

const TESTE = Axios.create({
    baseURL: "http://localhost:3000/"
  });

const AULAS = Axios.create({
  baseURL: "http://localhost:3001/"
});

const EVENTOS = Axios.create({
  baseURL: "http://localhost:3002/"
});

const LOGIN = Axios.create({
  baseURL: "http://localhost:3003/"
});

const FACBOOKPLAYAPI = Axios.create({
  //Devlopment url
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost/facbookplayportaldocenteapi/"
      : "https://docente.faculdadebookplay.edu.br/backend/facbookplayportaldocenteapi/"
});

export { TESTE, AULAS, EVENTOS, LOGIN, FACBOOKPLAYAPI };