
<template>
  <div>
    <Header @open-menu="isSidebarOpen = true" />

    <div class="altura-tela d-flex align-items-center">
      <Sidebar :is-open="isSidebarOpen" @close-menu="isSidebarOpen = false" />

      <main class="w-100">
        <div id="page-content">
          <div class="container container-conteudo box-direito">
            <router-view></router-view>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import Header from '@/components/NavHeader.vue';
import Sidebar from '@/components/MenuLateral.vue';

export default {
  name: 'TheDashboard',
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      isSidebarOpen: false
    };
  },
};
</script>

<style>
#page-content{
  width: auto;
}
.altura-tela {
  height: calc(100vh - 100px);
  padding: 0 70px;
}

#menu-responsivo ul.menu-itens li a span svg {
  stroke: #004F9F !important;
  margin-right: 10px;
}

#menu-responsivo ul.menu-itens li a.itemAtivo span svg {
  stroke: #FFFFFF !important;
}

@media (max-width: 1199px) {
  .altura-tela {
    padding: unset;
  }
}
</style>