<template>
  <header>
    <div class="d-flex flex-column">
      <div class="container-header d-flex">
        <div class="row w-100 justify-content-end">
          <div class="col-12">
            <div class="row">
              <div class="col-2 d-flex d-xl-none align-items-center">
                <label for="menuMobile" v-if="$route.path !== '/'">
                  <div class="container-logo" @click="$emit('open-menu')">
                    <img src="./../assets/img/menu-mobile.svg" alt="ícone menu mobile" style="cursor: pointer">
                  </div>
                </label>
              </div>
              <div class="col-7 col-md-4 col-xl-3 d-flex align-items-center div-container-logo">
                <div class="container-logo">
                  <img src="./../assets/img/FaculdadeBP_logotipo_horizontal.svg" alt="logo faculdade bookplay"
                    class="w-100" height="60px">
                </div>
              </div>
              <template v-if="$route.path.startsWith('/dashboard/')">
                <div class="col col-sm-3 col-md-6 col-xl-9 d-flex justify-content-end">
                  <div class="container-header-direita d-flex align-items-center">
                    <div class="box-notificacao">
                      <div class="container-notificacoes d-none d-lg-flex">
                        <img src="./../assets/img/icon-notificacao.svg" alt="ícone sino notificação" height="35px">
                        <div class="quantidade-notificacoes d-flex align-items-center justify-content-center">
                          <span>{{ numNotificacoes }}</span>
                        </div>
                        <label for="input-notificacao" class="d-lg-none">
                          <div class="container-notificacoes">
                            <img src="./../assets/img/icon-notificacao.svg" alt="ícone sino notificação" height="35px">
                            <div class="quantidade-notificacoes d-flex align-items-center justify-content-center">
                              <span>{{ numNotificacoes }}</span>
                            </div>
                          </div>
                        </label>
                        <div class="box-oculto infos-notificacoes flex-column align-items-center justify-content-center">
                          <div v-for="notif in notificacoes" :key="notif.titulo"
                            class="infos-notificacoes-item d-flex align-items-center justify-content-center">
                            <span class="origemMensagem">{{ notif.Titulo }}</span>
                            <span>{{ notif.DtInicio }}</span>
                          </div>
                          <span class="verTodasMensagens" @click="openModal">Ver todas</span>
                        </div>
                      </div>
                    </div>
                    <b-modal id="notifications-modal" hide-footer title="Notificações" centered size="lg"
                      title-class="custom-modal-title" header-class="custom-modal-header">

                      <div class="modal-content-center">
                        <ul v-if="unseenNotifications.length" class="notification-list">
                          <li v-for="notif in unseenNotifications" :key="notif.titulo" class="notification-item">
                            <strong class="notif-title">{{ notif.Titulo }}</strong> -
                            <span class="notif-date">{{ notif.DtInicio }}</span>
                          </li>
                        </ul>
                        <div v-else>
                          <b style="color: brown; font-size: large;">Você não possui novas notificações.</b>
                        </div>
                      </div>

                      <div class="text-right" v-if="unseenNotifications.length">
                        <b-button variant="secondary" @click="closeModal" class="mr-2">Fechar</b-button>
                        <b-button variant="primary" @click="markAllAsSeen">Marcar todas como lidas</b-button>
                      </div>
                    </b-modal>
                    <div class="box-usuario">
                      <div class="container-usuario d-none d-xl-flex align-items-center">
                        <div class="container-usuario-foto d-flex align-items-center justify-content-center">
                          <img src="https://static.bookplay.com.br/images/profile/default/1.jpg"
                            style="border-radius: 50%;" alt="foto de perfil do usuário" height="48px">
                        </div>
                        <span>{{ userName.split(' ')[0] }}</span>
                        <img src="./../assets/img/seta.svg" alt="ícone seta">
                        <div class="box-oculto infos-login-usuario">
                          <div class="container-usuario-interno d-flex align-items-center w-100 h-100">
                            <div class="container-usuario-foto d-flex align-items-center justify-content-center">
                              <img src="https://static.bookplay.com.br/images/profile/default/1.jpg"
                                style="border-radius: 50%;" alt="foto de perfil do usuário" height="90px">
                            </div>
                            <div class="usuario-infos w-100 d-flex flex-column">
                              <span class="nome-usuario">{{ userName.split(' ')[0] }}</span>
                              <span class="codigo-usuario">CODUSUARIO #{{ userUid }}</span>
                              <!-- <span>felipe.campos@mundialeditora.com</span> -->
                              <div class="d-flex mt-3">
                                <button @click="saindo" class="btn-sair">SAIR <img src="./../assets/img/icon-sair.svg"
                                    alt="ícone sair"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import { EventBus } from "@/EventBus.js";
import DiarioService from "@/services/DiarioService";

export default {
  name: "NavHeader",
  data() {
    return {
      notificacoes: [],
      userName: localStorage.getItem('user-name') || "", // Se não houver Nome, retorna uma string vazia
      userUid: localStorage.getItem('user-uid') || ""  // Se não houver Uid, retorna uma string vazia
    };
  },
  computed: {
    unseenNotifications() {
      if (Array.isArray(this.notificacoes)) {
        return this.notificacoes.filter(notif => !notif.seen);
      } else {
        return [];
      }
    },
    numNotificacoes() {
      return this.unseenNotifications.length;
    }
  },
  async created() {
    EventBus.$on("novoEventoAdicionado", (evento) => {
      let dataEvento = new Date(evento.start);
      dataEvento.setMinutes(dataEvento.getMinutes() + dataEvento.getTimezoneOffset());

      this.notificacoes.unshift({
        Titulo: evento.title,
        DtInicio: dataEvento.toLocaleDateString(),
        seen: false // Adicione esta linha
      });
    });

    // Buscar todas as notificações
    const response = await DiarioService.buscarTodasNotificacoes({ uid: this.userUid });
    if (Array.isArray(response.data.data)) {
      this.notificacoes = response.data.data;
    } else {
      this.notificacoes = [];  // Garantindo que notificacoes seja um array
    }
  },
  methods: {
    deleteCookie(name) {
      document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    saindo() {
      this.deleteCookie('portal do docente');
      this.deleteCookie('Token');
      localStorage.removeItem('user-name');
      localStorage.removeItem('user-uid');
      localStorage.removeItem('user-authenticated')
      this.$router.push('/')
    },
    closeModal() {
      this.$bvModal.hide('notifications-modal');
    },
    async markAsSeen(notif) {

      const eventId = notif.IdEvento;

      try {
        await DiarioService.AtualizarMensagensLidas({ id: eventId, uid: this.userUid });
      } catch (error) {
        console.error("Erro ao marcar notificação como lida:", error);
        // Você pode adicionar algum feedback para o usuário aqui, se desejar
      }

      const index = this.notificacoes.indexOf(notif);
      if (index !== -1) {
        this.notificacoes.splice(index, 1);
      }
    },
    async markAllAsSeen() {
      for (let notif of this.unseenNotifications) {
        await this.markAsSeen(notif);
      }
      this.closeModal();
    },
    openModal() {
      this.$bvModal.show('notifications-modal');
    }
  }
};
</script>

<style scoped>
/* HEADER */

.notification-list {
  list-style-type: none;
  /* Remove o marcador da lista */
  padding-left: 0;
}

.notification-item {
  margin-bottom: 10px;
  /* Espaçamento entre itens da lista */
}

.notif-title {
  font-weight: bold;
  /* Mensagem em negrito */
  margin-right: 10px;
}

.notif-date {
  margin-right: 10px;
  /* Espaçamento entre a data e o botão */
}

.modal-content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-header .modal-title.custom-modal-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.custom-modal-header button.close {
  position: absolute;
  right: 15px;
}

.notif-button {
  margin-left: 10px;
  /* Espaçamento entre o título/data e o botão */
}

@media (max-width: 767px) {
  .container-logo {
    max-width: 150px;
    justify-content: center;
  }
}
</style>