// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchCriteria: {
      tipo: null,
      valor: null
    },
    lastSearchCriteria: null, // Adicione isso
    searchResults: []
  },
  mutations: {
    SET_CRITERIA(state, criteria) {
      state.searchCriteria = criteria;
    },
    SET_LAST_SEARCH(state, criteria) { // Adicione esta mutation
      state.lastSearchCriteria = criteria;
    },
    SET_RESULTS(state, results) {
      state.searchResults = results;
    }
  }
});
