import { FACBOOKPLAYAPI } from "./Api";

export default {

  /**
   *  
   * @param {*} data
   */
  async buscarNotas({ tipo = "", valor = "" }) {
    return await FACBOOKPLAYAPI.get(`/facbookplay-notas-buscar/index.php?tipo=${tipo}&valor=${valor}`);
  },
  async buscarAulas(data) {
    return await FACBOOKPLAYAPI.get("facbookplay-aulas-buscar/", JSON.stringify(data));
  },

  async selecionarNotas(data) {
    return await FACBOOKPLAYAPI.get("facbookplay-notas-selecionar/", JSON.stringify(data));
  },

  async buscarEventos(data) {
    return await FACBOOKPLAYAPI.get("facbookplay-eventos-buscar/", JSON.stringify(data));
  },

  async buscarTodasNotificacoes(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-notificacoes-buscar/", JSON.stringify(data));
  },

  async inserirAula(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-aulas-inserir/", JSON.stringify(data));
  },

  async inserirEvento(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-eventos-inserir/", JSON.stringify(data));
  },

  async removerEvento(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-eventos-deletar/", JSON.stringify(data));
  },

  async AtualizarNotas(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-notas-editar/", JSON.stringify(data));
  },

  async AtualizarAula(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-aulas-editar/", JSON.stringify(data));

  },
  async AtualizarMensagensLidas(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-notificacoes-lidas/", JSON.stringify(data));

  },
  async validarUsuarioPortal(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-validar-usuario/", JSON.stringify(data));
  },
  async validarTokenAcesso(data) {
    return await FACBOOKPLAYAPI.post("facbookplay-validar-token/", JSON.stringify(data));
  }
}
