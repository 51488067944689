<template>
  <div>
    <b-form @submit.prevent="onFormSubmit">
      <b-row>
        <!-- ... Outros campos de filtro existentes ... -->

        <!-- Adicione o combobox de busca -->
        <b-col md="3">
          <label for="searchBy">Filtrar por:</label>
          <b-form-select v-model="selectedSearchField" :options="searchFields" id="searchBy"></b-form-select>
        </b-col>

        <!-- Adicione o campo de input para pesquisa -->
        <b-col md="3">
          <label for="searchInput">Pesquisar:</label>
          <!-- Se a seleção for "Data", exiba o campo de data -->
          <b-form-input v-if="selectedSearchField === 'Data'" type="date" v-model="searchValue"
          id="searchInput"></b-form-input>
          <!-- Caso contrário, exiba o campo de input padrão -->
          <b-form-input @keypress.enter="filterTable" v-else v-model="searchValue" id="searchInput"></b-form-input>
        </b-col>
        
        <b-col md="3" class="btnFiltrar">
          <b-button @click="filterTable(true)">Filtrar</b-button>
          <b-button v-if="userGrupo !== '62'" @click="insertItem" variant="success">Inserir</b-button>
        </b-col>
      </b-row>
    </b-form>
    <br>
    <b-table striped hover :items="paginatedData" :fields="fields" class="rounded-table">
      <template #cell(ações)="dados">
        <b-button @click="editItem(dados.item)" variant="info">Editar</b-button>
      </template>
      <!-- <template #cell(Data)="dados">
        {{ formatDate(dados.item.Data) }}
      </template> -->
    </b-table>
    <div class="text-center mt-3" v-if="filteredData.length > 8">
      <b-pagination v-model="currentPage" :total-rows="filteredData.length" :per-page="8" class="mt-3"></b-pagination>
    </div>
  </div>
</template>
  
<script>

import router from "../../router";
import DiarioService from "@/services/DiarioService";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      filteredData: [], // Agora vamos usar filteredData para exibir dados filtrados
      userGrupo: localStorage.getItem('user-grupo') || "",  // Se não houver Uid, retorna uma string vazia
      dados: [],
      Ano: [],
      CodTurma: [],
      CodDisciplina: [],
      currentPage: 1,
      fields: [
        'Etapa', 'CodDisciplina', 'AulaNumero', 'Data', 'Qtde',
        'Conteudo', 'ações'
      ],
      selectedSearchField: null, // Campo selecionado no combobox de busca
      searchValue: '', // Valor digitado para pesquisa
      searchFields: [  // Opções do combobox de busca
        { text: 'Etapa', value: 'Etapa' },
        { text: 'Código da Disciplina', value: 'CodDisciplina' },
        { text: 'Número da Aula', value: 'AulaNumero' },
        { text: 'Data', value: 'Data' },
        // Adicione outros campos de cabeçalho aqui, conforme necessário
      ],
    };
  },
  computed: {
    paginatedData() {
      // Calcule os dados a serem exibidos na página atual
      const start = (this.currentPage - 1) * 8;
      const end = start + 8;
      return this.filteredData.slice(start, end);
    },
  },
  mounted() {

    if (this.$route.params.itemData) {
      const itemData = JSON.parse(this.$route.params.itemData);
      this.ano = itemData.AnoSemestre;
      this.turma = itemData.CodTurma;
      this.coddisciplina = itemData.CodDisciplina;
      this.disciplina = itemData.Disciplina;
      this.etapa = itemData.Etapa;
      this.data = itemData.Data;
      this.qtde = itemData.Qtde;
      this.aulanumero = itemData.AulaNumero;
      this.conteudo = itemData.Conteudo;
      this.$root.$emit("data-edited", itemData);
    }

    this.buscarAula();

  },
  methods: {

    // formatDate(dateString) {
    //   if (!dateString) return '';

    //   const date = new Date(dateString);
    //   const day = String(date.getDate()).padStart(2, '0');
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const year = date.getFullYear();

    //   return `${day}-${month}-${year}`;
    // },
    filterTable(showAlert = false) {
      if (showAlert && (!this.selectedSearchField || !this.searchValue.trim())) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, preencha os campos para a busca!',
        });
        return;
      }
      let filteredData = this.dados;


      // Adicione um bloco para aplicar a pesquisa com base no campo selecionado
      if (this.selectedSearchField && this.searchValue) {
        filteredData = filteredData.filter(item => {
          const field = this.selectedSearchField; // Campo selecionado no combobox
          const value = this.searchValue.toLowerCase(); // Valor de pesquisa em minúsculas

          if (field === 'Data') {
            // Para datas, compare diretamente
            return item[field] === value;
          } else if (typeof item[field] === 'string') {
            return item[field].toLowerCase().includes(value);
          } else {
            return String(item[field]).toLowerCase().includes(value);
          }
        });
      }

      this.filteredData = filteredData; // Update filteredData after filtering
      // Atualize a página atual se ela estiver fora dos limites
      if (this.currentPage > Math.ceil(this.filteredData.length / 8)) {
        this.currentPage = 1;
      }

      if (showAlert && this.filteredData.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'Não encontramos resultados para a sua pesquisa!',
        });
      }
    },
    async buscarAula() {
      try {
        let response = await DiarioService.buscarAulas();

        if (response?.data?.status === 'ok') {

          var retornoBanco = response.data.data;

          this.dados = [...this.dados, ...retornoBanco];

          this.filterTable();
          return retornoBanco;



        }

      } catch (error) {
        console.log(error);
      }
    },
    editItem(item) {
      this.$router.push({
        name: "EditarAula",
        params: {
          itemData: JSON.stringify(item) // Pass the selected item data as a parameter
        }
      });
    },
    insertItem() {
      // Navegar para a página de inserção
      router.push({ name: 'InserirAula' }); // Substitua 'InserirAula' pelo nome da sua rota
    }
  }
};
</script>
  
<style>
.rounded-table {
  border-radius: 10px;
  /* Ajuste o valor conforme desejado */
  overflow: hidden;
}

.text-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* .b-pagination {
  display: inline-block;
  margin-bottom: 20px; */
  /* Ajuste o valor conforme desejado */
/* } */
</style>
  