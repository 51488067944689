<template lang="pt-BR">
  <section>
    <FullCalendar :options="calendarOptions" v-model="calendarOptions" />

    <b-modal v-model="showModal" title="Adicionar Evento" @ok="addEvent" @shown="focusInput" cancel-title="Cancelar" ok-title="Adicionar">
      <b-form-input ref="eventInput" v-model="newEventTitle" placeholder="Nome do evento"></b-form-input>
    </b-modal>

    <b-modal v-model="showDeleteModal" title="Deletar Evento" @ok="confirmDeleteEvent" cancel-title="Cancelar" ok-title="Deletar" ok-variant="danger">
      Tem certeza de que deseja deletar este evento?
    </b-modal>
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import DiarioService from "@/services/DiarioService";
import { EventBus } from "@/EventBus.js";

export default {
  name: 'TheHome',
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        locale: 'pt-br',
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next'
        },
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
      },
      eventos: [], // Mantenha seus eventos aqui
      eventId: 0,
      userName: localStorage.getItem('user-name') || "", // Se não houver Nome, retorna uma string vazia
      userUid: localStorage.getItem('user-uid') || "",  // Se não houver Uid, retorna uma string vazia
      showModal: false,
      selectedDateInfo: null,
      newEventTitle: '',
      showDeleteModal: false,
      eventToDelete: null,
    }
  },
  directives: {
    focus: {
      // Quando o elemento vinculado é inserido no DOM...
      inserted: function (el) {
        // Foca o elemento
        el.focus();
      }
    }
  },
  mounted() {

    // se nao tiver o cookie com o uid de login ou a validação da secretaria, redireciona
    if (typeof this.getCookie('portal do docente') === "undefined" || !this.validaTokenAcesso()) {
      this.$router.push('/')
    }

  },
  async created() {
    try {
      const response = await DiarioService.buscarEventos();
      const responseData = response.data;

      let eventos;

      if (Array.isArray(responseData.data)) {
        eventos = responseData.data.map(element => ({
          id: element.IdEvento,
          title: element.Titulo,
          start: element.DtInicio,
          end: element.DtFim,
          allDay: element.Diatodo,
          uid: this.userUid
        }));
      } else if (Array.isArray(responseData)) {
        eventos = responseData.map(evento => ({
          id: evento.IdEvento,
          title: evento.Titulo,
          start: evento.DtInicio,
          end: evento.DtFim,
          allDay: evento.DiaTodo,
          uid: this.userUid
        }));
      } else {
        eventos = [];
        console.error("Resposta da API não está no formato esperado.");
        return;
      }

      // console.log(eventos);

      this.eventos = eventos;
      this.calendarOptions.events = this.eventos; // atualiza eventos no calendarOptions
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }
  },

  methods: {

    focusInput() {
      this.$refs.eventInput.focus();
    },

    async validaTokenAcesso() {
      try {
        // fazer chamada na api para buscar os cursos
        let response = await DiarioService.validarTokenAcesso({
          // retorna apenas os numeros do cpf
          token: this.getCookie('Token')
        });

        if (response?.data?.status === 'ok') {

          // caso nao houver token na response do banco
          let retornoBanco = response.data.data;

          if (!retornoBanco.Token) this.$router.push('/') // manda pro login

        }
      } catch (error) {
        this.$router.push('/')
      }
    },

    handleDateSelect(selectInfo) {
      this.selectedDateInfo = selectInfo; // salva as informações da data selecionada
      this.showModal = true; // abre o modal
    },

    async addEvent() {
      let titulo = this.newEventTitle;
      let calendarApi = this.selectedDateInfo.view.calendar;

      calendarApi.unselect();

      if (titulo) {
        const newEvent = {
          title: titulo,
          start: this.selectedDateInfo.startStr,
          end: this.selectedDateInfo.endStr,
          allDay: this.selectedDateInfo.allDay,
          uid: this.userUid
        };

        const eventData = {
          titulo: titulo,
          dtinicio: this.selectedDateInfo.startStr,
          dtfim: this.selectedDateInfo.endStr,
          diatodo: this.selectedDateInfo.allDay,
          uidusuario: this.userUid,
          nome: this.userName
        };

        try {
          const response = await DiarioService.inserirEvento(eventData);

          // Adicione o ID ao evento depois de inserido no DB
          newEvent.id = response.data.id;

          // Adicione o evento à lista de eventos do calendário
          this.$set(this.calendarOptions.events, this.calendarOptions.events.length, newEvent);

          // EventBus.$emit("novoEventoAdicionado");
          EventBus.$emit("novoEventoAdicionado", newEvent);

        } catch (error) {
          console.error("Erro ao inserir o evento:", error);
        }
      }

      window.location.reload();

      this.showModal = false; // fecha o modal
      this.newEventTitle = ''; // limpa o título do novo evento
    },



    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    async handleEventClick(info) {
      this.eventToDelete = info.event;
      this.showDeleteModal = true;
    },

    async confirmDeleteEvent() {
      if (this.eventToDelete) {
        this.eventToDelete.remove();

        // deletar o evento do banco de dados
        this.eventId = this.eventToDelete.id;

        
        if (this.eventId) {
          try {
            await DiarioService.removerEvento({
              idEvento: this.eventId
            });
          } catch (error) {
            console.error("Erro ao remover o evento:", error);
          }
        }
        
        this.eventId.remove();
        this.eventToDelete = null; // Resetar a referência
      }

      window.location.reload();

      this.showDeleteModal = false; // Fechar o modal
    }

  }
}
</script>
