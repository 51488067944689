import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from './views/Login.vue'
import Dashboard from './views/Dashboard.vue';
import Home from './components/pages/Home.vue';
import DiarioNotas from './components/pages/DiarioNotas.vue';
import DiarioAula from './components/pages/DiarioAula.vue';
import TurmaProfessor from './components/pages/TurmaProfessor.vue';
import InserirAula from './components/pages/InserirAula.vue';
import EditarNotas from './components/pages/EditarNotas.vue';
import EditarAula from './components/pages/EditarAula.vue';




Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true },
      redirect: 'dashboard/home',
      children: [
        {
          path: 'home',
          name: 'dashboard-home',
          component: Home
        },
        {
          path: 'diarionotas',
          name: 'dashboard-diarionotas',
          component: DiarioNotas
        },
        {
          path: 'diarioaula',
          name: 'dashboard-diarioaula',
          component: DiarioAula
        },
        {
          path: 'turmaprofessor',
          name: 'dashboard-turmaprofessor',
          component: TurmaProfessor
        },
        {
          path: '/inserir-aula',
          component: InserirAula, // Componente da página de inserção
          name: 'InserirAula', // Nome da rota para a página de inserção
        },
        {
          path: '/editar-notas',
          component: EditarNotas, // Componente da página de inserção
          name: 'EditarNotas', // Nome da rota para a página de inserção
        },
        {
          path: '/editar-aula',
          component: EditarAula, // Componente da página de inserção
          name: 'EditarAula', // Nome da rota para a página de inserção
        },
      ]
    }
  ];

const router = new VueRouter({
  mode: "history",
  routes
});

function isAuthenticated() {
  return localStorage.getItem('user-authenticated') === 'true';
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated()) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
