<template>
    <div>
      <h2>Editar Aula</h2>
      <!-- Campos de inserção -->
      <b-form-group label="Etapa">
        <b-form-input v-model="etapa" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Código da Disciplina">
        <b-form-input v-model="disciplina" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Número da Aula">
        <b-form-input v-model="aulanumero" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="Datas">
        <b-form-datepicker v-model="data"></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Quantidade">
        <b-form-input v-model="qtde"></b-form-input>
      </b-form-group>
      <b-form-group label="Conteúdo">
        <b-form-textarea v-model="conteudo"></b-form-textarea>
      </b-form-group>
      <br>
      <b-button @click="submitItem" variant="primary">Salvar Dados</b-button>
        &nbsp;
      <b-button @click="btnvoltar" variant="warning">Voltar</b-button>

    </div>
  </template>
  
  <script>

import DiarioService from "../../services/DiarioService";

  export default {
    data() {
      return {
        // ano: "",
        // turma: "",
        disciplina: 0,
        etapa: 0,
        // matricula: "",
        data: null,
        qtde: 0,
        aulanumero: 0,
        conteudo: "",
        
        originalJSON: {}
      };
    },
  async mounted() {
  if (this.$route.params.itemData) {
    const itemData = JSON.parse(this.$route.params.itemData);
    // this.ano  =  itemData.AnoSemestre;
    // this.turma = itemData.CodTurma;
    this.disciplina = itemData.CodDisciplina;
    this.etapa = itemData.Etapa;
    this.data = itemData.Data;
    this.qtde = itemData.Qtde;
    this.aulanumero = itemData.AulaNumero;
    this.conteudo = itemData.Conteudo;
    this.originalJSON = itemData;
  }
},
  methods: {
    async submitItem() {
    try {

        // Call the service to update the data
        const response = await DiarioService.AtualizarAula({

          coddisciplina: this.disciplina,
          numaula: this.aulanumero,
          datas: this.data,
          qtde: this.qtde,
          conteudo: this.conteudo
        });

        if (response?.status === 200) {
          // Emit an event indicating that data was edited
          this.$root.$emit("data-edited", this.originalJSON);
          // Navigate back to the previous page (table)
          this.$router.back();
        } else {
          console.log("Error updating data.");
        }
      } catch (error) {
        console.error("Error editing data:", error);
      }
  },
  btnvoltar(){
    this.$router.push({name:'dashboard-diarioaula'})
  }
  }
  };
  </script>
  
  <style>
  /* Add your custom styles here */
  </style>
  