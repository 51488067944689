<template>
    <div>
      <b-table striped hover :items="tableData" :fields="fields"></b-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
          'codCurso', 'curso', 'turma', 'grade', 'etapa',
          'codDisciplina', 'disciplina', 'professor'
        ],
        tableData: [
          {
            codCurso: '001',
            curso: 'Engenharia Civil',
            turma: 'A',
            grade: '2023',
            etapa: '1',
            codDisciplina: '101',
            disciplina: 'Matemática',
            professor: 'João Silva'
          },
          {
            codCurso: '002',
            curso: 'Ciência da Computação',
            turma: 'B',
            grade: '2023',
            etapa: '1',
            codDisciplina: '201',
            disciplina: 'Programação',
            professor: 'Maria Rodrigues'
          },
          // Adicione mais linhas de dados fictícios aqui
        ]
      };
    }
  };
  </script>
  
  <style>
  /* Adicione estilos conforme necessário */
  </style>