<template>
  <div>
    <h2>Inserir Aula</h2>
    <!-- Campos de inserção -->
    <b-form-group label="Curso">
      <b-form-select v-model="selectedCurso" :options="cursosList" />
    </b-form-group>
    <b-form-group label="Disciplina" v-if="selectedCurso">
      <b-form-select v-model="novaAula.Disciplina.Nome" :options="filteredDisciplinas" />
    </b-form-group>
    <b-form-group label="Etapa">
      <b-form-input v-model="novaAula.Etapa"></b-form-input>
    </b-form-group>
    <b-form-group label="Número da Aula">
      <b-form-input v-model="novaAula.AulaNumero"></b-form-input>
    </b-form-group>
    <b-form-group label="Datas">
      <b-form-datepicker v-model="novaAula.Data"></b-form-datepicker>
    </b-form-group>
    <b-form-group label="Quantidade">
      <b-form-input v-model="novaAula.Qtde"></b-form-input>
    </b-form-group>
    <b-form-group label="Conteúdo">
      <b-form-textarea v-model="novaAula.Conteudo"></b-form-textarea>
    </b-form-group>

    <!-- Botões de ação -->
    <div class="mt-3">
      <b-button @click="salvarAula" variant="success">Salvar</b-button>
      &nbsp;
      <b-button @click="btnvoltar" variant="warning">Voltar</b-button>
    </div>
  </div>
</template>
  
<script>

import DiarioService from "@/services/DiarioService";
import Swal from 'sweetalert2';

export default {
  data() {


    return {

      novaAula: {
        CodCurso: 0,
        Disciplina: {
          CodDisciplina: 0,
          Nome: ""
        },
        Etapa: 0,
        Qtde: 0,
        Data: "",
        AulaNumero: 0,
        Conteudo: ""
      },
      todo: [],
      cursosList: [],
      disciplinasList: [],
      selectedCurso: null,
    };
  },
  watch: {
    selectedCurso(newVal) {
      this.novaAula.CodCurso = newVal;
    }
  },
  computed: {
    // filteredDisciplinas() {
    //   return this.todo
    //         .filter(item => item.CodCurso === this.selectedCurso && item.Disciplina)
    //         .map(item => item.Disciplina);
    // }
    filteredDisciplinas() {
      return this.todo
        .filter(item => item.CodCurso === this.selectedCurso && item.Disciplina)
        .map(item => ({ text: item.Disciplina, value: { CodDisciplina: item.CodDisciplina, Nome: item.Disciplina } }));
    }
  },
  mounted() {
    this.getAula();
  },
  methods: {

    async getAula() {
      try {
        const response = await DiarioService.selecionarNotas();
        this.todo = response.data.data;

        // Construindo a lista de cursos a partir da resposta, evitando duplicatas
        const uniqueCursoCodes = [...new Set(this.todo.map(item => item.CodCurso))];
        this.cursosList = uniqueCursoCodes.map(code => {
          const found = this.todo.find(item => item.CodCurso === code);
          return { text: found.Curso, value: found.CodCurso };
        });

      } catch (error) {
        console.error(error);
      }
    },
    async salvarAula() {
      try {
        const response = await DiarioService.inserirAula({
          codcurso: this.novaAula.CodCurso,
          coddisciplina: this.novaAula.Disciplina.Nome.CodDisciplina,
          codaula: this.novaAula.AulaNumero,
          etapa: this.novaAula.Etapa,
          dtaula: this.novaAula.Data,
          qtdeaula: this.novaAula.Qtde,
          conteudo: this.novaAula.Conteudo
        });

        if (response?.data?.status === 'ok') {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Aula inserida com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'dashboard-diarioaula' });
            }
          });
        } else {
          // Trate erros ou exiba mensagens de feedback
        }
      } catch (error) {
        console.error(error);
        // Lide com erros de comunicação com a API
      }
    },
    btnvoltar() {
      this.$router.push({ name: 'dashboard-diarioaula' })
    }
  },
};
</script>
  
<style>
/* Estilize conforme necessário */
</style>
  