<template>
  <div>
    <b-form @submit.prevent="onFormSubmit">
      <b-row>
        <b-col md="3">
          <label for="filtro">Filtrar por :</label>
          <b-form-select v-model="selectedFilterType" :options="filterTypes" id="filtro">
          <template #first>
            <option :value="null" disabled>{{ selectedFilterType ? '' : 'Selecione um tipo' }}</option>
          </template>
        </b-form-select>
        </b-col>
        <b-col md="3">
          <label for="valor">Categoria :</label>
          <b-form-input @keypress.enter="updatePage" v-model="filterValue" id="valor" :placeholder="filterValue ? '' : 'Insira um valor'" @input="validateNumericInput"></b-form-input>
        </b-col>
        <b-col md="3" class="btnFiltrar">
          <b-button @click="updatePage">Filtrar</b-button>
        </b-col>
      </b-row>
    </b-form>
    <br>
    <div>
      <b-table v-if="filteredData.length > 0" :items="paginatedData" :fields="fields" striped hover class="rounded-table">
        <template #cell(Ações)="dados">
          <b-button variant="info" @click="editItem(dados.item)">Editar</b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination v-if="totalRows > 10" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
          aria-controls="my-table" @change="updatePage"></b-pagination>
      </div>
      <!-- <b-button @click="insertItem" variant="success" class="btnInsert">Inserir</b-button> -->
    </div>
    <div v-if="filteredData.length === 0 && mensagemErro == 'Não encontramos registros para a filtragem selecionada.'"
      class="no-data-message">
      <h4 style="color: red;">Não encontramos registros para a filtragem selecionada.</h4>
    </div>
    <div v-else-if="filteredData.length === 0" class="no-data-message">
      <h4 style="color: red;">Para acessar o diario de notas filtre a cima o que deseja.</h4>
    </div>
  </div>
</template>

<script>

import DiarioService from "../../services/DiarioService";
import Swal from 'sweetalert2';
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      filterTypes: [
        'Selecione um tipo',
        'Matricula',
        'MatSituacao',
        'CodCurso',
        'Curso',
        'CodTurma',
        'Turma',
        'CodAluno',
        'Aluno',
        'CodDisciplina',
        'disciplina',
        'Professor',
        'Etapa',
        'Nota1',
        'Nota2',
        'Nota3',
        'NotaForum',
        'NotaMedia'
      ],
      selectedFilterType: null,
      filterValue: null,
      selectedYear: null,
      selectedClass: null,
      selectedSubject: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      hasMorePages: true,
      mensagemErro: "",
      filteredData: [], // Initialize as an empty array
      dados: [],
      Ano: [],
      tipo: '',
      valor: null,
      CodTurma: [],
      //CodDisciplina: [],
      Disciplina: [],
      fields: [
        'Curso',
        'Turma',
        'Disciplina',
        'Professor',
        'Etapa',
        'Aluno',
        'Matricula',
        'MatSituacao',
        'Nota1',
        'Nota2',
        'Nota3',
        'NotaForum',
        'NotaMedia',
        'Ações'
      ]
    };
  },

  computed: {
    ...mapState(['searchCriteria', 'searchResults']),

    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.dados.slice(start, end);
    }
  },

  mounted() {
    if (this.searchCriteria.tipo && this.searchCriteria.valor) {
      this.tipo = this.searchCriteria.tipo;
      this.valor = this.searchCriteria.valor;

      this.selectedFilterType = this.searchCriteria.tipo || 'Selecione o tipo';
      this.filterValue = this.searchCriteria.valor || 'Insira um valor';

      this.fetchData();
    } else if (this.lastSearchCriteria) { // Verifique a última busca
      this.tipo = this.lastSearchCriteria.tipo;
      this.valor = this.lastSearchCriteria.valor;

      this.selectedFilterType = this.lastSearchCriteria.tipo || 'Selecione o tipo';
      this.filterValue = this.lastSearchCriteria.valor || 'Insira um valor';

      this.fetchData();
    }else {
      this.selectedFilterType = 'Selecione um tipo';
      this.filterValue = '';
    }
    if (this.$route.params.itemData) {
      const itemData = JSON.parse(this.$route.params.itemData);
      this.matSituacao = itemData.MatSituacao;
      this.turma = itemData.CodTurma;
      this.coddisciplina = itemData.CodDisciplina;
      this.nome = itemData.Disciplina;
      this.etapa = itemData.Etapa;
      this.aluno = itemData.CodAluno;
      this.matricula = itemData.Matricula;
      this.professor = itemData.Professor;

      this.codturma = itemData.CodTurma;
      this.situacao = itemData.MatSituacao;
      this.av1 = parseFloat(itemData.Nota1).toFixed(2);
      this.av2 = parseFloat(itemData.Nota2).toFixed(2);
      this.av3 = parseFloat(itemData.Nota3).toFixed(2);
      this.forum = parseFloat(itemData.NotaForum).toFixed(2);
      this.media = parseFloat(itemData.NotaMedia).toFixed(2);

      // Emit an event indicating that data was edited
      this.$root.$emit("data-edited", itemData);

    }
  },

  methods: {

    ...mapMutations(['SET_CRITERIA', 'SET_RESULTS', 'SET_LAST_SEARCH']),


    updatePage() {



      // Aqui, vamos ajustar para usar selectedFilterType como tipo e filterValue como valor.
      this.tipo = this.selectedFilterType;
      this.valor = this.filterValue;


      if (!this.selectedFilterType || !this.filterValue) {
        Swal.fire({
          icon: 'error',
          title: 'Campos Obrigatórios',
          text: 'Por favor, preencha os campos Filtro e Categoria.'
        });
        return;
      }

      this.SET_LAST_SEARCH({
        tipo: this.tipo,
        valor: this.valor
      });

      this.fetchData();
      // console.log(this.fetchData());
      this.SET_CRITERIA({
        tipo: this.tipo,
        valor: this.valor
      });
    },
    async fetchData() {
      try {
        const response = await DiarioService.buscarNotas({
          tipo: this.tipo,
          valor: this.valor
        });


        this.SET_RESULTS(this.dados);


        if (response?.status === 200 && response?.data.length > 0) {
          this.dados = response.data;

          this.filteredData = this.dados;

          this.totalRows = this.dados.length;
          // Se o número de registros é menor que perPage, assumimos que não há mais páginas.
          this.hasMorePages = this.currentPage > 1 || (this.currentPage * this.perPage) < this.totalRows;

        }
        else {
          this.dados = [];
          this.filteredData = [];
          this.totalRows = 0;
          this.hasMorePages = false;
          this.mensagemErro = "Não encontramos registros para a filtragem selecionada.";
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        this.hasMorePages = this.currentPage > 1 || (this.currentPage * this.perPage) < this.totalRows;
      }
    },
    editItem(item) {
      this.$router.push({
        name: "EditarNotas",
        params: {
          itemData: JSON.stringify(item) // Pass the selected item data as a parameter
        }
      });
      console.log(item);
    },
    validateNumericInput() {
    const numericFilters = [
      'Matricula',
      'CodCurso',
      'CodTurma',
      'CodAluno',
      'CodDisciplina',
      'Etapa',
      'Nota1',
      'Nota2',
      'Nota3',
      'NotaForum',
      'Media'
    ];

    if (numericFilters.includes(this.selectedFilterType)) {
    if (/[^0-9]/.test(this.filterValue)) { // Verifica se há caracteres não numéricos
      this.filterValue = '';  // Limpa o campo de entrada
      Swal.fire({   // Usa SweetAlert para notificar o usuário
        icon: 'error',
        title: 'Entrada inválida',
        text: 'Apenas valores numéricos são permitidos para este filtro.'
      });
    }
  }
  },
  created() {
    // Listen for the event and update the table data accordingly
    this.$root.$on("data-edited", editedData => {
      const index = this.dados.findIndex(item => item.IdMatricula === editedData.IdMatricula);
      if (index !== -1) {
        // Update the data in the table
        this.dados[index] = editedData;
      }
    });
    }
  }
};
</script>

<style>
.btnFiltrar {
  margin-top: auto;
}

.rounded-table {
  border-radius: 10px;
  /* Ajuste o valor conforme desejado */
  overflow: hidden;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

}

.pagination-btn {
  margin: 0 5px;
  /* Isso adiciona uma margem horizontal de 5px em cada botão */
}

.btnFiltrar>button:not(:last-child) {
  margin-right: 10px;
}
</style>