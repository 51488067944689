<template>
  <div>
    <!-- <Header /> -->
    <main>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-sm-12 d-flex justify-content-center">
            <div class="container-login justify-content-center">
              <div class="container-login-imagem d-none d-lg-flex">
                <img src="../assets/img/homem-sorrindo-para-tela-do-computador.png"
                  alt="Homem em um fundo azul com um computador na mão e sorrindo para a tela">
              </div>
              <div class="container-login-conteudo">
                <form @submit="onSubmit" id="formLogin" :class="{ mx_form_invalido: formInvalido }">
                  <div class="container-login-topo">
                    <span>Login</span>
                  </div>
                  <div class="form-login">
                    <span>Informe seu Login e Senha para acessar o portal</span>
                    <div class="form-group">
                      <input type="tel" v-model="login" id="user" name="user" placeholder="LOGIN"
                        style="text-align: center" required>
                      <div class="form-senha"
                        style="width: 100%; max-width: 380px; display: flex; align-items: flex-end;">
                        <input :type="showPassword ? 'text' : 'password'" v-model="senha" id="password" name="password"
                          placeholder="SENHA" style="text-align: center" required>
                        <div @click="togglePasswordVisibility">
                          <img v-if="showPassword" id="mostrarSenha" src="../assets/img/ocultarSenha.svg"
                            alt="Ícone para exibir ou ocultar a senha" style="cursor: pointer;">
                          <img v-else id="mostrarSenha" src="../assets/img/mostrarSenha.svg"
                            alt="Ícone para exibir ou ocultar a senha" style="cursor: pointer;">
                        </div>
                      </div>
                    </div>
                    <!-- <div class="container-login-permecer-conectado">
                      <input type="checkbox" id="permanecer-conectado" class="d-none" checked>
                      <label for="permanecer-conectado">
                        <div class="input-permanecer-conectado"></div>
                        <span>Permanecer conectado</span>
                      </label>
                    </div> -->
                    <span> {{ responseMessage }} </span>
                    <div class="container-validacoes d-flex flex-column align-items-center">
                      <div :class="{ mx_recaptcha_failed: !recaptcha }">
                        <template>
                          <vue-recaptcha @verify="mxVerify"
                          sitekey="6LeNu_4oAAAAALaIT8CEQmzfJdKQLsOjOrTxGxTn"></vue-recaptcha>
                        </template>
                        <b><span style="color: red; font-size: larger;justify-content: space-around;" class="avisoCaptcha">Complete o Captcha!</span></b>
                      </div>
                    </div>
                    <button type="submit" class="button-acesso" id="btnLogin">Entrar</button>
                  </div>

                  <!-- <button type="button" id="redefinir-senha" class="link-redefinir-senha">Esqueci a minha senha</button> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import Header from '../components/NavHeader.vue';
import DiarioService from "@/services/DiarioService";
import Swal from 'sweetalert2';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "TheLogin",
  components: {

    VueRecaptcha
  },
  data() {
    return {
      login: "",
      cpf: '',
      senha: '',
      responseMessage: "",
      showPassword : false,
      recaptcha    : null,
      formInvalido : false
    };
  },
  mounted() {
    // this.setPageDetails();

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    document.head.appendChild(recaptchaScript)

  },
  methods: {
    mxVerify(response) {
      this.recaptcha = response;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit(event) {

      // validação login
      if (this.login.length < 1) {
        this.responseMessage = 'Preencha o campo com um Usuário válido!';
        this.openPopup();
        return;
      }

      event.preventDefault(); // Prevent default form action



      // Collect form data
      // const loginData = {
      //   cpf: this.cpf,
      //   senha: this.senha
      // };

      try {

        if (!this.recaptcha) {
          this.formInvalido = true;
          document.querySelector('.avisoCaptcha').style.display = 'flex';
          return false;
        }

        // Try to authenticate the user
        const response = await DiarioService.validarUsuarioPortal({
          login: this.login,
          senha: this.senha
        });

        if (response?.data?.status === 'ok') {

          // variaveis que ira utilizar
          var retornoBanco = response.data.data;

          // armazena o Nome e Uid no localStorage
          localStorage.setItem('user-name', retornoBanco.Nome);
          localStorage.setItem('user-uid', retornoBanco.Uid);
          localStorage.setItem('user-grupo', retornoBanco.CodGrupo);



          var date = new Date();
          date.setDate(date.getDate() + 1);
          var expires = 'expires=' + date.toGMTString();

          //salva o login no cookie
          document.cookie = ' portal do docente = ' + retornoBanco.Uid + '; ' + expires + '; path=/';
          document.cookie = ' Token = ' + retornoBanco.Token + '; ' + expires + '; path=/';

          localStorage.setItem('user-authenticated', 'true')

          //   redireciona para a página de historico de requerimentos
          this.$router.push('/dashboard')
        }
        else {
          Swal.fire({
            title: 'Erro!',
            text: 'As credenciais de login estão incorretas.',
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn-error' // adds custom class
            }
          });
        }
      }
      catch (error) {
        // Handle errors, like network failures or unexpected errors
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao tentar fazer login.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    },
  },
};
</script>

<style scoped>
/* LOGIN */
main {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 100px);
}

.avisoCaptcha {
  display: none;
}

#formLogin {
  height: 100%;
}

.container-login {
  display: flex;
  width: 100%;
  max-width: 990px;
  max-height: 739px;
}

.container-login-conteudo {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  max-height: 739px;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

}

.container-login-topo {
  width: 100%;
  height: 82px;
  border-top-right-radius: 10px;
  background-color: #00ADE4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.container-login-topo span {
  font-family: 'Effra Bold', sans-serif;
  font-size: 32px;
  color: #FFFFFF;
}

.form-login,
.form-group {
  font-family: 'Effra Light', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-login {
  height: calc(100% - 82px);
}

.form-group input#user,
.form-group input#password,
.form-senha,
#cpf_usuario {
  font-size: 25px;
  color: #515151;
  border: none;
  border-bottom: 1px solid #515151;
  background-color: unset;
  width: 100%;
  max-width: 380px;
  margin-top: 10px;
  padding-bottom: 6px;
}

.form-group input#password {
  border: unset;
  padding: unset;
}

.form-group input::placeholder {
  font-size: 25px;
  color: #A6A6A6;
}

.container-login-permecer-conectado label span {
  font-family: 'Effra Regular', sans-serif;
  font-size: 18px;
  color: #515151;
}

/* LOGIN INPUT PERMANECER CONECTADO */
.input-permanecer-conectado {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #00ADE4;
  position: relative;
  margin-right: 12px;
}

#permanecer-conectado~label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 22px;
}

#permanecer-conectado:checked~label>.input-permanecer-conectado::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00ADE4;
  position: absolute;
  top: 3px;
  left: 3px;
}

/* FIM LOGIN INPUT PERMANECER CONECTADO */

.button-acesso {
  font-size: 35px;
  font-weight: 700;
  color: #00ADE4;
  width: 100%;
  max-width: 250px;
  height: 60px;
  border-radius: 15px;
  background-color: unset;
  border: 1px solid #00ADE4;
  margin: 46px 0 20px;
}

button#btnLogin {
  font-family: 'Effra Medium', sans-serif;
  font-size: 26px;
}

.button-acesso:hover {
  color: #FFFFFF;
  background-color: #00ADE4;
}

.link-redefinir-senha,
.link-primeiro-acesso {
  font-family: 'Effra Regular', sans-serif;
  font-size: 16px;
  color: #00ADE4;
  text-decoration: underline;
  background: transparent;
  border: 0;
}

@media (max-width: 767px) {
  .container-login-topo {
    border-top-left-radius: 10px;
  }

  .container-login-conteudo {
    border-bottom-left-radius: 10px;
  }

  .container-login-topo span {
    font-size: 28px;
  }

  .form-group input {
    max-width: calc(100% - 40px);
  }

  .form-group input#user,
  .form-group input#password,
  .form-senha,
  .form-group input::placeholder .container-login-permecer-conectado label span {
    font-size: 18px;
  }

  .container-login-permecer-conectado {
    width: 100%;
    max-width: 380px;
    display: flex;
    justify-content: flex-end;
  }

  .input-permanecer-conectado {
    margin-right: 12px;
  }

  .form-login {
    padding: 25px 30px 10px;
  }

  .button-acesso {
    font-size: 22px;
    margin-top: 26px;
  }

  .link-redefinir-senha,
  .link-primeiro-acesso {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .container-login {
    max-width: 715px;
    max-height: 409px;
  }

  .form-login {
    height: unset;
  }

  .container-login-topo span {
    font-size: 22px;
  }

  .form-group input#user,
  .form-group input#password,
  .form-senha,
  #cpf_usuario {
    font-size: 18px;
  }

  .container-login-topo {
    height: 52px;
  }

  .button-acesso {
    height: 40px;
    margin: 10px 0;
  }

  #formLogin {
    height: unset;
  }

  .container-login-conteudo {
    max-height: unset;
  }

  .container-login-imagem>img:nth-child(1) {
    width: 100%;
  }
}
</style>
